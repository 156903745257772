import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AdsGETApiAuth, AdsPUTApi, AdsPUTApiToken, ImageURL, UserMeEdit } from '../../Constant/api';
import { slugs } from '../../Constant/slugs';
import { login, logout } from '../../redux/action';
import store from '../../redux/store';
import ImageUpload from '../ImageUpload';
import SideBar from '../SideBar';

export default function Profile() {
  const Login = useSelector((state) => state.LoginState);
  const dispatch = useDispatch()
  const [Name, setName] = React.useState("")
  const [Photo, setPhoto] = React.useState("")
  const [PhoneNumber, setPhoneNumber] = React.useState("")
  const [user, setUser] = React.useState({ roles: ['subscriber'] })
  const [userDetails, setUserDetails] = React.useState({})
  const [usersDataID, setusersDataID] = React.useState("")
  const [UserFetch, setUserFetch] = React.useState(false)
  const [BatchStatus, setBatchStatus] = React.useState("")
  const [SubscriptionPressed, setSubscriptionPressed] = React.useState(false)
  const [PaymentMethod, setPaymentMethod] = React.useState("")
  const [ScreenShotUrl, setScreenShotUrl] = React.useState("")
  const [SubscriptionVerifyDone, setSubscriptionVerifyDone] = React.useState(false)
  const [reload, setReload] = React.useState(false)
  const [BankImageSelect, setBankImageSelect] = React.useState(false)
  const [BankImageUrl, setBankImageUrl] = React.useState("")

  const LoginState = useSelector((state) => state.LoginState);
  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")
  const [editProfileModal, setEditProfileModal] = React.useState(false)
  const [changePasswordModal, setChangePasswordModal] = React.useState(false)
  const [oldPassword, setOldPassword] = React.useState("")
  const [newPassword, setNewPassword] = React.useState("")
  const [newConfirmPassword, setNewConfirmPassword] = React.useState("")

  const UserDetails = () => {
    setUserFetch(false)
    setReload(true)
    const id = Login.userID
    AdsGETApiAuth({
      status: "any",
      "filter[meta_query][1][key]": "user_id",
      "filter[meta_query][1][value][0]": id,
      "filter[meta_query][1][compare]": "=",
      _fields: "title,meta,slug,id"
    }, "user_data")
      .then((response) => {
        if (response.data.length !== 0) {
          setUserDetails(response.data[0])
          setUserFetch(true)
        }
        else {
          dispatch(logout())
        }
      })
      .catch(e => {
        if (e.message.includes("Request failed with status code 500"))
          dispatch(logout())
      })
      .finally(() => {
        setReload(false)
      })
  }
  const EditUser = () => {
    setReload(true)
    AdsPUTApi(
      {
        meta: {
          user_profile_picture_user: Photo,
          user_phone_number: PhoneNumber
        }
      },
      "users_data", usersDataID
    ).then(res => {
      setReload(false)
    }).catch(() => {

    })
  }
  const UserDetailsSubscriptionEdit = () => {
    setReload(true)
    const date = new Date();
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    const data2 = {}

    console.log(usersDataID)
    AdsPUTApi(data2, "users_data", usersDataID)
      .then((res) => {
        setReload(false)
        setSubscriptionVerifyDone(true)
        console.log(res.data)
      }).catch((err) => {
        console.warn(err);
        console.warn(err.response.data);
      })
  }
  useEffect(() => {
    UserDetails()
  }, [])
  const AlertComponent = () => {
    return (
      <div className="modal"
        onClick={() => {
          setAlertShow(false)
        }}
      >
        <div>
          <div className="row " style={{ minHeight: "10vh", marginTop: "10vh" }}>
            <div className="col-lg-4 col-1"></div>
            <div className="col-lg-4 col-10 pb-5  bg-white  shadow">
              <div className="p-5">
                <div dangerouslySetInnerHTML={{ __html: Alertcomp }}></div>
              </div>
              <div style={{ display: 'flex', cursor: 'pointer' }} className="center">
                <div className="sign-in-button-1 col-8">
                  OK
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className="row">
        <div className="col-2">
        </div>
        {UserFetch &&
          <section className="col-8 form-view mt-3">
            <center style={{ margin: "10px" }}>
              {userDetails.meta.user_profile_picture !== "" ?
                <img src={userDetails.meta.user_profile_picture}
                  style={{ width: "100px", height: "100px", borderRadius: "100px", border: "1px solid black" }}
                />
                :
                <div className="center"
                  style={{ fontSize: "70px", width: "100px", height: "100px", borderRadius: "100px", border: "1px solid black" }}
                >
                  <i className="fa fa-user"
                  />
                </div>
              }
            </center>
            <div className="col-10">
              <table>
                <tbody>
                  <tr>
                    <td>User Name</td>
                    <td>{userDetails.meta.user_name}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>{userDetails.meta.user_phone_number}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{userDetails.meta.user_email}</td>
                  </tr>
                  <tr>
                    <td>Verification Status</td>
                    <td>{userDetails.meta.verification_status}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>{userDetails.meta.user_role}</td>
                  </tr>

                  <tr>
                    <td>Batch Name</td>
                    <td>{userDetails.meta.batch_name}</td>
                  </tr>
                  {BatchStatus !== "" &&
                    <tr>
                      <td>Batch Status</td>
                      <td>{BatchStatus}</td>
                    </tr>
                  }
                  {/* <tr>
                    <td>Subscription Start Date</td>
                    <td>{userDetails.meta.user_subscription_start}</td>
                  </tr>
                  <tr>
                    <td>Subscription End Date</td>
                    <td>{userDetails.meta.user_subscription_end}</td>
                  </tr> */}
                </tbody>
              </table>
              {/* <div style={{ margin: "20px 10px" }}>
                <h4>Verification History</h4>
              </div>
              <div>
              </div> */}
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4" style={{ padding: "3px " }}
                    onClick={() => {
                      setChangePasswordModal(true)
                    }}
                  >
                    Change Password
                  </div>
                </div>
              </div>
            </div>
          </section>
        }

      </div>


      {changePasswordModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setChangePasswordModal(true)
                  }}
                >X</div>
                <table>
                  <tbody>
                    <tr>
                      <td>Old Password</td>
                      <td>
                        <input
                          className="input-common-2 col-12"
                          value={oldPassword} placeholder="Old Password"
                          onChange={(event) => { setOldPassword(event.target.value) }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>New Password</td>
                      <td>
                        <input
                          className="input-common-2 col-12"
                          value={newPassword} placeholder="New Password"
                          onChange={(event) => { setNewPassword(event.target.value) }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Confirm Password</td>
                      <td>
                        <input
                          className="input-common-2 col-12"
                          value={newConfirmPassword} placeholder="Confirm New Password"
                          onChange={(event) => { setNewConfirmPassword(event.target.value) }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="row center m-2">
                  <div className=" col-3" style={{ padding: "0 10px" }}>
                    <div className="sign-in-button-4" style={{ padding: "3px " }}
                      onClick={() => {
                        setUserFetch(false)
                        setReload(true)
                        const id = Login.userID
                        const dataWithPassword = {
                          "password": newConfirmPassword,
                        }
                        AdsPUTApiToken(dataWithPassword, `users`, `${id}`, `${window.btoa(`${userDetails.meta.user_name}:${oldPassword}`)}`)
                          .then(res => {
                            dispatch(login(
                              `${id}`,
                              `${userDetails.meta.user_name}`,
                              `${window.btoa(`${userDetails.meta.user_name}:${newConfirmPassword}`)}`,
                              `${userDetails.meta.user_email}`,
                              `${userDetails.meta.user_role}`,
                              ``,
                              `${PhoneNumber}`,
                              ``,
                            ))
                            setReload(false)
                            setUserFetch(true)
                            setChangePasswordModal(false)
                            setAlertcomp("Password Changed Successfully")
                            setAlertShow(true)
                          })
                          .catch(e => {
                            console.log(e)
                            setAlertcomp("Old Password didnot match. Please Contact Admin")
                            setAlertShow(true)
                            setReload(false)
                            setUserFetch(true)
                            setChangePasswordModal(false)
                            // if (e.message.includes("Request failed with status code 500"))
                            //   dispatch(logout())
                          })
                      }}
                    >
                      Done
                    </div>
                  </div>
                  <div className=" col-3" style={{ padding: "0 10px" }}>
                    <div className="sign-in-button-4" style={{ padding: "3px " }}
                      onClick={() => {
                        setChangePasswordModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      }

      {
        editProfileModal &&
        <section className="modal" style={{ padding: "10vh" }}>
          <div className="row">
            <div className="col-lg-3">

            </div>
            <div className="col-lg-6" style={{ backgroundColor: "#fff", padding: "30px" }}>
              <div style={{ textAlign: 'right', fontSize: 22, padding: 10, cursor: 'pointer' }}
                onClick={() => {
                  setEditProfileModal(false)
                }}
              >
                <i className="fa fa-close" />
              </div>
              Edit Profile Picture
              <ImageUpload
                ImageUrl={Photo}
                setImageUrl={setPhoto}
              />
              <div>
                <div style={{ margin: "10px 0 3px" }}><b>Edit Phone Number</b></div>
                <div className="row" >
                  <input className="my-2 p-2 px-2 col-11" style={{ margin: "30px" }}
                    type="text"
                    value={PhoneNumber}
                    onChange={(event) => {
                      setPhoneNumber(event.target.value.replace(/\D/g, ""))
                    }}
                    placeholder="Edit Phone Number"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-3" />
                <div className="sign-in-button-1 col-6"
                  onClick={() => {
                    EditUser()
                    setEditProfileModal(false)
                  }}
                >
                  Update Profile
                </div>
              </div>
            </div>
            <div className="col-lg-3">

            </div>
          </div>
        </section>

      }

      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent />
      }
    </div >
  )
}