import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
import { login } from '../../../../redux/action';
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)
  const Login = useSelector((state) => state.LoginState);

  const [author, setAuthor] = React.useState(FetchData.author)
  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
  const [SubscriptionStart, setSubscriptionStart] = React.useState(FetchData.meta.user_subscription_start)
  const [SubscriptionEnd, setSubscriptionEnd] = React.useState(FetchData.meta.user_subscription_end)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState("")
  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
  const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)
  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])

  const [verifiedExamList, setVerifiedExamList] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_exam_list)
  )
  const [BatchExamDeleteIndex, setBatchExamIndex] = React.useState("")
  const [BatchExamDeleteModal, setBatchExamDeleteModal] = React.useState(false)
  const [BatchExam, setBatchExam] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_exam_list))
  const [BatchExamCache, setBatchExamCache] = React.useState(
    FetchData.meta.verified_exam_list == "" ? [] :
      JSON.parse(FetchData.meta.verified_exam_list))

  const [AddNew, setAddNew] = React.useState(false)
  const [DeleteAll, setDeleteAll] = React.useState(false)
  const [serachName, setSearchName] = React.useState("")
  const [ExamArray, setExamArray] = React.useState([])
  const [ExamArray1, setExamArray1] = React.useState([])
  const [ResultArray, setResultArray] = React.useState([])
  const [ResultArray1, setResultArray1] = React.useState([])
  const [UBTC, setUBTC] = React.useState(0)


  const [userVerificationHistoryModal, setUserVerificationHistoryModal] = React.useState(false)
  const [editKey, setEditKey] = React.useState(FetchData.meta.user_verification_history)
  const [EditScreenShot, setEditScreenShot] = React.useState("")
  const [EditStartDate, setEditStartDate] = React.useState("")
  const [EditEndDate, setEditEndDate] = React.useState("")
  const [EditAmountPaid, setEditAmountPaid] = React.useState("")
  const [EditRemarks1, setEditRemarks1] = React.useState("")
  const [EditRemarks2, setEditRemarks2] = React.useState("")
  const [subscriptionTime, setSubscriptionTime] = React.useState("")
  const [Datapassword, setDataPassword] = React.useState(FetchData.id == "" ? true : false)
  const [NumberModal, setNumberModal] = React.useState(false)
  const [VerifyTime, setTime] = React.useState("")

  // const [Name, setName] = React.useState(FetchData.title.rendered)
  const dispatch = useDispatch()

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const AfterUserCreate = (id) => {
    const data = {
      "title": Title,
      "status": "publish",
      "author": id,
      "meta": {
        "user_role": UserRole,
        "user_profile_picture": ProfilePicture,
        "user_subscription_start": SubscriptionStart,
        "user_subscription_end": SubscriptionEnd,
        "user_subscription_time": subscriptionTime,
        "user_phone_number": PhoneNumber,
        "verification_status": VerificationStatus,
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": userEmail,
        "user_verification_history": userVerificationHistory,
        "verified_exam_list": JSON.stringify(BatchExam),
        "batch_id": batchID,
        "batch_name": batchName
      }
    }
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_user_data)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.student_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_user_data, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const EditInfo = () => {
    setReload(true)

    if (ID == "") {
      const dataWithPassword = {
        "username": userName,
        "email": userEmail,
        "password": Password,
        "roles": UserRole
      }
      AdsPOSTApi(dataWithPassword, "users")
        .then(res => {
          AfterUserCreate(res.data.id)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      if (Datapassword) {
        const dataWithPassword = {
          "password": Password,
          "roles": UserRole
        }
        if (Login.userID !== userID)
          AdsPUTApi(dataWithPassword, `users`, `${userID}`)
            .then(res => {
              AfterUserCreate(userID)
            })
            .catch(e =>
              console.log(e)
            )
        else {
          setAlertShow(true)
          setAlertcomp("You are not allowed to cahnge owns password")
          setReload(false)

          // dispatch(login(
          //   `${Login.id}`,
          //   `${Login.userName}`,
          //   `${window.btoa(`${userName}:${Password}`)}`,
          //   `${Login.userEmail}`,
          //   `${Login.userRole}`,
          //   `${Login.userPhoto}`,
          //   `${Login.userPhoneNumber}`,
          //   `${Login.userBatch}`,
          // ))
          // AdsPUTApi(dataWithPassword, `users`, `${userID}`)
          //   .then(res => {
          //     AfterUserCreate(userID)
          //   })
          //   .catch(e =>
          //     console.log(e)
          //   )
        }
      }
      else {
        const data = {
          "roles": UserRole
        }
        AdsPUTApi(data, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
    }
  }

  const dateChangePressed = (verifyTime) => {
    const date = new Date();

    const monthToday = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const yearToday = date.getUTCFullYear() < 9 ? `0${date.getUTCFullYear()}` : date.getUTCFullYear();

    const monthnew = (date.getMonth() + 1 + verifyTime) % 12;
    const month = monthnew < 9 ? `0${monthnew}` : `${monthnew}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    const year = date.getMonth() < (11 - verifyTime) ? `${date.getUTCFullYear()}` : `${date.getUTCFullYear() + 1}`
    setEditStartDate(`${yearToday}-${monthToday}-${day}`)
    setEditEndDate(`${year}-${month}-${day}`)

  }

  useEffect(() => {
    BatchList()
    FetchExamList()
    FetchResultList()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const FetchExamList = () => {
    AdsGETApiAuth(
      { per_page: 500, _fields: "id,alug,title,meta.featured_image,meta.exam_type" },
      slugs.db_slug_paid_exam
    )
      .then((res) => {
        setExamArray1(res.data)
        setExamArray(res.data.filter(a => a.meta.exam_type == "UBT"))
      })
      .catch(e => {
        console.log(e)
      })
  }
  const FetchResultList = () => {
    AdsGETApiAuth(
      {
        per_page: 10000, _fields: "id,slug,title,meta.exam_name_results,meta.student_id_results,meta.exam_id_results",
        "filter[meta_query][1][key]": "student_id_results",
        "filter[meta_query][1][value][0]": userID,
        "filter[meta_query][1][compare]": "=",
      },
      slugs.db_slug_paid_results
    )
      .then((res) => {
        setResultArray1(res.data)
        setResultArray(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const [ExamName, setExamName] = React.useState("")
  const [ExamAllocated, setExamAllocated] = React.useState("")
  const [ExamNewQuota, setExamNewQuota] = React.useState("")
  const [Examkey, setExamkey] = React.useState("")
  const [ExamData, setExamData] = React.useState({})

  const MappingExam = ({ item }) => {
    const EnrollmentSearch = Object.values(BatchExam).filter(a => a.exam_id == item.id)
    const [CheckON, setCheckON] = React.useState(EnrollmentSearch.length == 1 ? true : false)
    // if (EnrollmentSearch.length !== 1)
    var arrayValue = Object.values(BatchExam)
    var quota = 0;

    return (
      <>
        <td>
          <i className="fa fa-square-o" />
        </td>
        <td>{item.id}</td>
        <td>{item.title.rendered}</td>
        <td style={{ cursor: "pointer" }} onClick={() => {
          // setBatchExam(BatchExamCache)
          setAddNew(false)
          setExamData(item)
          console.log(arrayValue)

          for (let i = 0; i < arrayValue.length; i++) {
            console.log(arrayValue[i].exam_id)
            if (arrayValue[i].exam_id == item.id) {
              setExamAllocated(arrayValue[i].quota)
              setExamkey(Object.keys(BatchExam)[i])
              quota = arrayValue[i].quota
              break;
            }
          }
          // var BatchExamCacheData = {
          //   ...BatchExam,
          //   [Object.values(BatchExam).length]: {
          //     exam_id: item.id,
          //     featured_image: item.meta.featured_image,
          //     name: item.title.rendered,
          //     exam_type: item.meta.exam_type,
          //     quota: quota,
          //     slug: item.slug
          //   }
          // }
          // setBatchExam(BatchExamCacheData)
          setNumberModal(true)
          // }
        }}
        >Choose</td>

      </>
    )
  }

  return (
    <div>

      {NumberModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setNumberModal(false)
                  }}
                >X</div>
                {/* Add Alloted Number to set */}
                <table>
                  <tbody>
                    <tr>
                      <td>Exam ID</td>
                      <td>{ExamData.exam_id}</td>
                    </tr>
                    <tr>
                      <td>Name</td>
                      <td>{ExamData.title.rendered}</td>
                    </tr>
                    <tr>
                      <td>Allocate</td>
                      <td>
                        <input
                          className="input-common-2 col-12"
                          value={ExamNewQuota} placeholder="Quota"
                          onChange={(event) => {
                            setExamNewQuota(event.target.value)

                            // setBatchExam(BatchExamCacheData)
                            // var BatchExamCacheData = {
                            //   ...BatchExam,
                            //   [Object.keys(BatchExam)[index]]: {
                            //     ...item,
                            //     quota: event.target.value,
                            //   }
                            // }
                            // setBatchExam(BatchExamCacheData)
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="row center m-2">
                  <div className=" col-sm-3" style={{ padding: "0 10px" }}>
                    <div className="sign-in-button-4" style={{ padding: "3px " }}
                      onClick={() => {
                        setExamNewQuota(ExamNewQuota)
                        console.log(BatchExam,Examkey)
                        var BatchExamCacheData;
                        if (Examkey == "")
                          BatchExamCacheData = {
                            ...BatchExam,
                            [Object.values(BatchExam).length]: {
                              exam_id: ExamData.id,
                              featured_image: ExamData.meta.featured_image,
                              name: ExamData.title.rendered,
                              exam_type: ExamData.meta.exam_type,
                              quota: ExamNewQuota,
                              slug: ExamData.slug
                            }
                          }
                        else
                          BatchExamCacheData = {
                            ...BatchExam,
                            [Examkey]: {
                              exam_id: ExamData.id,
                              featured_image: ExamData.meta.featured_image,
                              name: ExamData.title.rendered,
                              exam_type: ExamData.meta.exam_type,
                              quota: Number(ExamNewQuota) + Number(BatchExam[Examkey].quota),
                              slug: ExamData.slug
                            }
                          }
                        setBatchExam(BatchExamCacheData)
                        setNumberModal(false)
                        setExamkey("")
                        setExamNewQuota("")
                        setExamData({})
                      }}
                    >
                      Done
                    </div>
                  </div>
                  <div className=" col-sm-3" style={{ padding: "0 10px" }}>
                    <div className="sign-in-button-4" style={{ padding: "3px " }}
                      onClick={() => {
                        setNumberModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>

                {/* {ExamName}
                {ExamAllocated}
                {Examkey} */}
              </div>
            </div>
          </div>
        </section>
      }

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Student Information </center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Student Name </td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Student Role</td>
                <td className="col-6">
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"subscriber"} />
                  {/* <CustomButton setData={setUserRole} Data={UserRole} DataValue={"student"} /> */}
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setUserRole("student")
                    }}
                  >
                    <div className={(UserRole !== "author" && UserRole !== "tabuser" && UserRole !== "subscriber") ? "active-option" : "passive-option"}>
                      Student
                    </div>
                  </button>
                  <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                    onClick={() => {
                      setUserRole("author")
                    }}
                  >
                    <div className={UserRole == "author" ? "active-option" : "passive-option"}>
                      Admin
                    </div>
                  </button>
                  <CustomButton setData={setUserRole} Data={UserRole} DataValue={"tabuser"} />

                </td>
              </tr>
              <tr>
                <td className="col-3">Verification Status</td>
                <td className="col-6">
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"verified"} />
                  <CustomButton setData={setVerificationStatus} Data={VerificationStatus} DataValue={"None"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Profile Picture</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={ProfilePicture}
                    setImageUrl={setProfilePicture}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Phone Number</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PhoneNumber} placeholder="Phone Number"
                    onChange={(event) => { setPhoneNumber(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">User Name</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userName} placeholder="User Name"
                      onChange={(event) => { setUserName(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userName}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">User Email</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userEmail}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">Password</td>
                <td className="col-6">
                  {Datapassword ?
                    <input className="input-common"
                      value={Password} placeholder="Password"
                      onChange={(event) => { setPassword(event.target.value) }}
                    />
                    :
                    <div>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setDataPassword(true)
                          setPassword("")
                        }}
                      >
                        <div className={"passive-option"}>
                          Change Password
                        </div>
                      </button>
                    </div>
                  }
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Student
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam List</center></h4>
          <div className="row center m-2">
            <div className=" col-sm-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4" style={{ padding: "3px " }}
                onClick={() => {
                  setAddNew(true)
                }}
              >
                Add New
              </div>
            </div>
            <div className=" col-sm-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4" style={{ padding: "3px " }}
                onClick={() => {
                  setDeleteAll(true)
                }}
              >
                Delete All
              </div>
            </div>
          </div>
          <div style={{overflowX:"scroll"}}>
          <table>
            <tbody>
              <tr>
                <td>Exam ID</td>
                <td style={{width:"200px"}}>Name</td>
                <td>Alloted</td>
                <td>Attempted</td>
                {/* <td className="col-2" style={{textAlign:"center"}}>Attempted</td> */}
                <td>Edit</td>
                <td>Type</td>
              </tr>

              {Object.values(BatchExam).map((item, index) => (
                <tr key={index}>
                  <td>{item.exam_id}</td>
                  <td style={{width:"200px"}}>{item.name}</td>
                  <td>{item.quota}</td>
                  <td>{ResultArray.filter(a => a.meta.exam_id_results == item.exam_id).length}</td>
                  {/* <td className="col-2">
                    <input
                      className="input-common-2 col-12"
                      value={item.quota} placeholder="Quota"
                      onChange={(event) => {
                        var BatchExamCacheData = {
                          ...BatchExam,
                          [Object.keys(BatchExam)[index]]: {
                            ...item,
                            quota: event.target.value,
                          }
                        }
                        setBatchExam(BatchExamCacheData)
                      }}
                    />

                  </td> */}
                  <td style={{ cursor: "pointer" }} onClick={() => {
                    setBatchExamIndex(index)
                    setBatchExamDeleteModal(true)
                  }}>Delete</td>
                </tr>
              ))}

            </tbody>
          </table>
          {/* {JSON.stringify(BatchExam)}aa */}
          </div>
        </div>
      </div>
      {BatchExamDeleteModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchExamDeleteModal(false)
                  }}
                >X</div>
                Do you really want to delete Exam?
                <table>
                  <tbody>
                    <tr>
                      <td>Exam Name : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].name}</td>
                    </tr>
                    <tr>
                      <td>Exam ID : </td>
                      <td>{BatchExam[BatchExamDeleteIndex].exam_id}</td>
                    </tr>
                  </tbody>
                </table>
                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        BatchExam.splice(BatchExamDeleteIndex, 1)
                        // BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setBatchExamDeleteModal(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {DeleteAll &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setDeleteAll(false)
                  }}
                >X</div>
                Do you really want to delete all Exam?

                <div className="row" >
                  <div className="col-1 center " />
                  <div className="col-5 center " >
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setDeleteAll(false)
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                  <div className="col-5 center">
                    <div className="sign-in-button-4" style={{ width: "80%" }}
                      onClick={() => {
                        setBatchExam([])
                        // BatchExam.splice(BatchExamDeleteIndex, 1)
                        // BatchExamID.splice(BatchExamDeleteIndex, 1)
                        setDeleteAll(false)
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {AddNew &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10 shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setAddNew(false)
                  }}
                >X</div>
                <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                  <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                    value={serachName} placeholder="Name"
                    onChange={(event) => {
                      setSearchName(event.target.value)
                      setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                    }}
                  />
                  <div className={UBTC == 0 ? "examselectStudent" : "examunselectStudent"}
                    onClick={() => {
                      setUBTC(0)
                      setExamArray(ExamArray1.filter(a => a.meta.exam_type == "UBT"))
                    }}
                  >
                    UBT
                  </div>
                  <div className={UBTC == 1 ? "examselectStudent" : "examunselectStudent"} onClick={() => {
                    setUBTC(1)
                    setExamArray(ExamArray1.filter(a => a.meta.exam_type == "Chapter"))
                  }} >
                    ChapterWise
                  </div>
                </div>

                <div style={{ height: "400px", overflowY: "scroll" }}>
                  <table>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>Exam ID</td>
                        <td>Exam Name</td>
                        <td>Choose</td>
                      </tr>
                      {ExamArray.map((item, index) => (
                        <tr key={index}
                          onClick={() => {

                          }}
                        >
                          <MappingExam item={item} />
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ padding: "10px" }}>
                  <div className="row">
                    <div className="col-1" />
                    <div className="col-5 center " >
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExamCache(BatchExam)
                          // setBatchExamIDCache(BatchExamID)
                          setAddNew(false)
                        }}
                      >
                        Cancel
                      </div>
                    </div>
                    {/* <div className="col-5 center">
                      <div className="sign-in-button-4" style={{ width: "80%" }}
                        onClick={() => {
                          setBatchExam(BatchExamCache)
                          setAddNew(false)
                        }}
                      >
                        Done
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      }
      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}